.home {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; // Center vertically
    flex-direction: column;

    &__text-wrapper {
        display: flex;
        justify-content: center;
        align-items: center; // Center text vertically

        h1 {
            font-family: "Barrio", system-ui;
            font-weight: 500;
            color: var(--yellow-theme-main-color);
            margin: 0; // Remove default margin
            line-height: 10rem;
            position: relative;
            overflow: hidden;
            white-space: nowrap; // Keep this for typing effect
            animation: typingEffectAnimation 1.5s steps(30, end);
        }
    }

    &__contact-me {
        display: flex;
        justify-content: center;
        margin-top: 15px;
    }

    button {
        cursor: pointer;
        padding: 10px 70px;
        font-size: 2rem; // Fixed extra space in font size
        background: transparent;
        color: var(--yellow-theme-main-color);
        border: 1.5px solid var(--yellow-theme-main-color);
        border-radius: 4px;
        font-weight: 500;
        margin: 0 10px;

        &:hover {
            background: var(--yellow-theme-main-color);
            color: black;
        }
    }
}

// Responsive styles
@media only screen and (max-width: 550px) {
    .home__text-wrapper h1 {
        font-size: 3rem;
        line-height: 5rem;
    }
}

@media only screen and (min-width: 551px) and (max-width: 768px) {
    .home__text-wrapper h1 {
        font-size: 4rem;
        line-height: 6rem;
    }
}

@media only screen and (min-width: 769px) and (max-width: 3000px) {
    .home__text-wrapper h1 {
        font-size: 6rem;
        line-height: 8rem;
    }
}

// Animation keyframes
@keyframes typingEffectAnimation {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}
